import { Logo } from '@components';
import { KargoDrawer } from '@components/kargo-ui/drawer';
import { DashboardAdminLinkButton } from '@components/layout';
import styled from '@emotion/styled';
import { ApolloClientProvider } from '@kargo/context/apollo-client';
import {
  Auth0TokenContext,
  Auth0TokenProvider,
} from '@kargo/context/auth0-token';
import { AuthenticatedUserProvider } from '@kargo/context/authenticated-user';
import { FacilityProvider } from '@kargo/context/facility';
import { PermissionsProvider } from '@kargo/context/permissions';
import { StatsigClientProvider } from '@kargo/context/statsig-client';
import { MenuOpen } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { PERMISSIONS_MAP } from 'constants/permissions-map';
import Link from 'next/link';
import { useContext, useState } from 'react';
import { DASHBOARD_NAV_HEADER_HEIGHT, SIDE_NAV_WIDTH } from './constants';
import { DashboardActionsMenu } from './dashboard-header/actions-menu';
import { DashboardHeaderNavigation } from './dashboard-header/dashboard-header-navigation';
import type { DashboardBreadcrumb } from './dashboard-header/dashboard-header-navigation/dashboard-header-navigation';
import { DashboardUserSettingsMenu } from './dashboard-header/dashboard-user-settings-menu';
import { DashboardNav } from './dashboard-nav';
import { DashboardReportIssue } from './report-issue';
import { DashboardNotifications } from './dashboard-header/dashboard-notifications';

const StyledDashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(p) => p.theme.colors.contentPrimary};
  background: ${(p) => p.theme.colors.backgroundPrimary};
  overflow: hidden;
`;

type StyledDashboardContentContainerProps = {
  isNavDrawerOpen: boolean;
};

const StyledDashboardContentContainer = styled.div<StyledDashboardContentContainerProps>`
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${DASHBOARD_NAV_HEADER_HEIGHT});
  width: ${(p) =>
    p.isNavDrawerOpen ? `calc(100% - ${SIDE_NAV_WIDTH})` : '100%'};
  margin-left: ${(p) => (p.isNavDrawerOpen ? `${SIDE_NAV_WIDTH}` : 0)};
  transition: all 0.2s ease-out;

  background: ${({ theme }) => theme.colors.backgroundPrimary};
  color: ${({ theme }) => theme.colors.contentPrimary};
  overflow: auto;
`;

type StyledDashboardHeaderContainerProps = {
  isNavDrawerOpen: boolean;
};

const StyledDashboardHeaderContainer = styled.div<StyledDashboardHeaderContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${DASHBOARD_NAV_HEADER_HEIGHT};
  width: ${(p) =>
    p.isNavDrawerOpen ? `calc(100% - ${SIDE_NAV_WIDTH} + 55px)` : '100%'};
  margin-left: ${(p) =>
    p.isNavDrawerOpen ? `calc(${SIDE_NAV_WIDTH} - 55px)` : 0};
  transition: all 0.2s ease-out;

  padding: 8px 24px;
  background: ${(p) => p.theme.colors.backgroundPrimary};
  border-bottom: 1px solid ${(p) => p.theme.colors.borderPrimary};
`;

const StyledDashboardHeaderMain = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const StyledOpenNavDrawerIconButton = styled(IconButton)`
  transition: opacity 0.1s ease-in;

  :hover {
    opacity: 0.6;
    background: none;
  }
`;

const StyledOpenNavDrawerIcon = styled(MenuOpen)`
  transform: scaleX(-1);
`;

const StyledDashboardHeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledNavContainer = styled.div`
  width: ${SIDE_NAV_WIDTH};
  min-height: 100vh;
  color: ${(p) => p.theme.colors.contentPrimary};
  background-color: ${(p) => p.theme.colors.sidebarBackground};
  overflow: auto;
`;

const StyledNavLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${DASHBOARD_NAV_HEADER_HEIGHT};
  background-color: ${(p) => p.theme.colors.black};
`;

const StyledLogoLink = styled.a`
  margin-left: 26px;

  :focus-visible {
    outline: 1px auto ${(p) => p.theme.colors.white};
  }
`;

const StyledDashboardReportIssueContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 44px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 12px;
`;

const StyledCloseNavDrawerIconButton = styled(IconButton)`
  padding-top: 10px;
  transition: opacity 0.1s ease-in;

  :hover {
    opacity: 0.6;
  }

  :focus-visible {
    border-radius: 0;
    outline: 1px auto ${(p) => p.theme.colors.white};
  }
`;

const StyledCloseNavDrawerIcon = styled(MenuOpen)`
  font-size: 28px;
  color: ${(p) => p.theme.colors.white};
`;

type DashboardLayoutContentProps = {
  breadcrumbs?: DashboardBreadcrumb[];
  children: React.ReactNode;
};

const DashboardLayoutContent = ({
  breadcrumbs,
  children,
}: DashboardLayoutContentProps) => {
  const { hasPermission } = useContext(Auth0TokenContext);

  const [isNavDrawerOpen, setIsNavDrawerOpen] = useState<boolean>(true);

  const canSeeKargoEmployeeButtons = hasPermission(
    PERMISSIONS_MAP.INTERNAL_USER,
  );

  return (
    <StyledDashboardContainer>
      <StyledDashboardHeaderContainer isNavDrawerOpen={isNavDrawerOpen}>
        <StyledDashboardHeaderMain>
          <StyledOpenNavDrawerIconButton
            onClick={() => {
              setIsNavDrawerOpen(true);
            }}
          >
            <StyledOpenNavDrawerIcon />
          </StyledOpenNavDrawerIconButton>

          <DashboardHeaderNavigation breadcrumbs={breadcrumbs} />
        </StyledDashboardHeaderMain>

        <StyledDashboardHeaderActions>
          <DashboardActionsMenu />

          <DashboardUserSettingsMenu />

          <DashboardNotifications />
        </StyledDashboardHeaderActions>
      </StyledDashboardHeaderContainer>

      <StyledDashboardContentContainer isNavDrawerOpen={isNavDrawerOpen}>
        {children}
      </StyledDashboardContentContainer>

      <KargoDrawer
        variant='persistent'
        anchor='left'
        classes={{
          paper: 'dashboard-nav-drawer',
        }}
        open={isNavDrawerOpen}
      >
        <StyledNavContainer>
          <StyledNavLogoContainer>
            <Link href='/' passHref>
              <StyledLogoLink>
                <Logo />
              </StyledLogoLink>
            </Link>

            <StyledCloseNavDrawerIconButton
              onClick={() => {
                setIsNavDrawerOpen(false);
              }}
            >
              <StyledCloseNavDrawerIcon />
            </StyledCloseNavDrawerIconButton>
          </StyledNavLogoContainer>

          <DashboardNav />

          {canSeeKargoEmployeeButtons && (
            <StyledDashboardReportIssueContainer>
              <DashboardAdminLinkButton toAdmin />
              <DashboardReportIssue />
            </StyledDashboardReportIssueContainer>
          )}
        </StyledNavContainer>
      </KargoDrawer>
    </StyledDashboardContainer>
  );
};

type Props = DashboardLayoutContentProps;

const DashboardLayout = (props: Props): JSX.Element => {
  return (
    <Auth0TokenProvider>
      <ApolloClientProvider>
        <StatsigClientProvider>
          <AuthenticatedUserProvider>
            <FacilityProvider>
              <PermissionsProvider>
                <DashboardLayoutContent {...props} />
              </PermissionsProvider>
            </FacilityProvider>
          </AuthenticatedUserProvider>
        </StatsigClientProvider>
      </ApolloClientProvider>
    </Auth0TokenProvider>
  );
};

export { DashboardLayout };
