import styled from '@emotion/styled';
import Fab from '@mui/material/Fab';
import { useRouter } from 'next/router';

const StyledOpenAdminDashboardButton = styled(Fab)`
  margin-bottom: 10px;
  background-color: ${(p) => p.theme.colors.white};
  transition: opacity 0.2s ease-in-out;

  :hover,
  :focus-visible {
    opacity: 0.7;
  }
`;

const StyledOpenAdminDashText = styled.p`
  color: ${(p) => p.theme.colors.black};
  font-size: 0.75rem;
  text-transform: capitalize;
`;

const GetMetabaseExceptionDashboardUrl = (exceptionId: string | string[]) =>
  `https://metabase.kargo.zone/dashboard/18-exception-debugger?exception_external_id=${exceptionId}`;

type Props = {
  setCurrentDashboardUrlInLocalStorage: () => void;
};

const AdminLinkButton = ({ setCurrentDashboardUrlInLocalStorage }: Props) => {
  const router = useRouter();

  const onAdminLinkButtonClick = () => {
    const base = 'https://admin.mykargo.com';
    const { query } = router;

    setCurrentDashboardUrlInLocalStorage();

    if (query.activity && query.pallet && query.type === 'loading') {
      router.push(`${base}/labels-edit?pallet_id=${query.pallet}`);
    } else if (query.activity && query.type === 'loading') {
      router.push(
        `${base}/shipments/${query.shipmentId}/loading_events/${query.activity}`,
      );
    } else if (['/shipments/[shipmentId]'].includes(router.pathname)) {
      router.push(`${base}/shipments/${query.shipmentId}`);
    } else if (query.exceptionId) {
      window.open(
        GetMetabaseExceptionDashboardUrl(query.exceptionId as string),
      );
    } else {
      router.push(`${base}`);
    }
  };

  return (
    <StyledOpenAdminDashboardButton
      variant='extended'
      size='small'
      onClick={(e) => {
        e.preventDefault();
        onAdminLinkButtonClick();
      }}
    >
      <StyledOpenAdminDashText>Admin Dashboard</StyledOpenAdminDashText>
    </StyledOpenAdminDashboardButton>
  );
};

export { AdminLinkButton };
