import { usePermissions } from 'hooks/kargo-ui/use-permissions';
import {
  ChartBarIcon,
  ClipboardListIcon,
  CogIcon,
  ExclamationCircleIcon,
  OfficeBuildingIcon as FacilityIcon,
  HomeIcon,
  TruckIcon,
} from '@heroicons/react/outline';
import { Fragment } from 'react';
import { useGate } from 'statsig-react';
import { ExpandableNavItem } from '../../../components/navigation/expandable-nav-item';
import { NavItem } from '../../../components/navigation/nav-item';
import type { NavLinkItemProps } from '../../../components/navigation/nav-item/nav-item';

const DashboardNav = (): JSX.Element => {
  const { permissions } = usePermissions();
  const { value: showUserActivityAnalytics } = useGate(
    'show_user_activity_analytics',
  );
  const { value: showShipmentOriginAnalytics } = useGate(
    'show_origin_of_shipments_analytics',
  );
  const { value: showDwellTime } = useGate('show_analytics_dwell_time');
  const { value: showEfficiencyRating } = useGate(
    'show_analytics_efficiency_rating',
  );
  const { value: showTrailerAudit } = useGate('show_trailer_audit_analytics');
  const { value: showASN } = useGate('allow_asn_flow');

  const { hasBusinessSettingsPermission } = permissions;

  type DashboardLinkType = NavLinkItemProps & {
    subNavItems?: DashboardLinkType[];
  };

  const dashboardLinks: DashboardLinkType[] = [
    {
      label: 'Shipments',
      href: '/shipments',
      iconComponent: TruckIcon,
    },
    {
      label: 'Orders',
      href: '/orders',
      iconComponent: ClipboardListIcon,
    },
    {
      label: 'Exceptions',
      href: '/exceptions',
      iconComponent: ExclamationCircleIcon,
    },
    {
      label: 'Facility',
      href: '/facility',
      iconComponent: FacilityIcon,
    },
  ];

  const analyticsLinks: DashboardLinkType = {
    label: 'Analytics',
    href: '/analytics',
    isDisabled: true,
    iconComponent: ChartBarIcon,
  };

  const analyticSubNavItems = [
    {
      label: 'Pallet Insights',
      href: '/analytics/pallet-insights',
    },
    {
      label: 'Exceptions',
      href: '/analytics/exceptions',
    },
    {
      label: 'Shift Performance',
      href: '/analytics/shift-performance',
    },
    {
      label: 'Workflow Compliance',
      href: '/analytics/workflow-compliance',
    },
    {
      label: 'User Activity',
      href: '/analytics/user-activity',
      isVisible: showUserActivityAnalytics,
    },
    {
      label: 'Suppliers',
      href: '/analytics/suppliers',
      isVisible: showShipmentOriginAnalytics,
    },
    {
      label: 'ASN Analytics',
      href: '/analytics/asn',
      isVisible: showASN,
    },
  ];

  if (showDwellTime) {
    analyticSubNavItems.push({
      label: 'Dwell Time',
      href: '/analytics/dwell-time',
    });
  }

  if (showEfficiencyRating) {
    analyticSubNavItems.push({
      label: 'Efficiency Rate',
      href: '/analytics/efficiency-rate',
    });
  }

  if (showTrailerAudit) {
    analyticSubNavItems.push({
      label: 'Trailer Audit',
      href: '/analytics/trailer-audit',
    });
  }

  analyticsLinks.subNavItems = analyticSubNavItems;
  dashboardLinks.push(analyticsLinks);

  if (hasBusinessSettingsPermission) {
    dashboardLinks.unshift({
      label: 'Home',
      href: '/home',
      iconComponent: HomeIcon,
    });
  }

  if (hasBusinessSettingsPermission) {
    dashboardLinks.push({
      label: 'Settings',
      href: '/settings',
      iconComponent: CogIcon,
      subNavItems: [
        {
          label: 'Users',
          href: '/settings/users',
        },
        {
          label: 'Shifts',
          href: '/settings/shifts',
        },
        {
          label: 'Docks',
          href: '/settings/docks',
        },
        {
          label: 'Email Groups',
          href: '/settings/email-groups',
        },
        {
          label: 'Exceptions',
          href: '/settings/exceptions',
        },
      ],
    });
  }

  return (
    <>
      {dashboardLinks.map((dashboardLink) => {
        const { href, subNavItems } = dashboardLink;

        return (
          <Fragment key={href}>
            {subNavItems ? (
              <ExpandableNavItem expandableNavLinkItem={dashboardLink} />
            ) : (
              <NavItem navLinkItem={dashboardLink} />
            )}
          </Fragment>
        );
      })}
    </>
  );
};

export { DashboardNav };
