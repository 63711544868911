import styled from '@emotion/styled';
import type { SelectProps } from '@mui/material';
import { Select } from '@mui/material';

const KargoSelect = styled((props: SelectProps) => (
  <Select
    variant='standard'
    {...props}
    sx={{
      '& .MuiSelect-select': {
        '&:focus': {
          backgroundColor: 'inherit',
        },
      },
      ...props.sx,
    }}
    MenuProps={{
      sx: {
        '& .MuiMenu-list': {
          padding: 0,
        },
      },
      ...props.MenuProps,
    }}
    classes={{ ...props.classes }}
  />
))`
  font-size: ${(p) => p.theme.sizing.scale700};
  font-weight: 400;
`;

export { KargoSelect };
