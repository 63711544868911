import type { SerializedStyles, Theme } from '@emotion/react';
import { css } from '@emotion/react';
import {
  TruckDirection,
  type GetShipmentColorsFragment,
  ShipmentStatus,
} from 'generated/graphql';

type Direction =
  | 'shipping'
  | 'shipping-highlight'
  | 'receiving'
  | 'receiving-highlight'
  | 'unknown'
  | 'neutral'
  | 'mixed'
  | 'unused';

function getColorDirection(
  shipment: GetShipmentColorsFragment | null,
  showHighlight: boolean,
): Direction {
  if (!shipment) {
    return 'neutral';
  }

  const { direction, status } = shipment;
  let colorDirection: Direction = 'neutral';

  if (
    direction === TruckDirection.shipping &&
    status === ShipmentStatus.DOOR_ASSIGNED &&
    showHighlight
  ) {
    colorDirection = 'shipping-highlight';
  } else if (
    direction === TruckDirection.receiving &&
    status === ShipmentStatus.DOOR_ASSIGNED &&
    showHighlight
  ) {
    colorDirection = 'receiving-highlight';
  } else if (direction !== null) {
    colorDirection = direction;
  }

  return colorDirection;
}

export function getShipmentColor(
  shipment: GetShipmentColorsFragment | null,
  theme: Theme,
  showHighlight = false,
): string {
  const { colors } = theme;
  const colorDirection = getColorDirection(shipment, showHighlight);

  return {
    shipping: colors.dockBadgeShipping,
    'shipping-highlight': colors.dockBadgeShipping,
    receiving: colors.dockBadgeReceiving,
    'receiving-highlight': colors.dockBadgeReceiving,
    mixed: colors.dockBadgeMixed,
    neutral: colors.dockBadgeIdle,
    unknown: colors.dockBadgeUnused,
    unused: colors.dockBadgeUnused,
  }[colorDirection];
}

export function getShipmentColorStyles(
  shipment: GetShipmentColorsFragment,
  theme: Theme,
  options: {
    disabled?: boolean;
    showHighlight?: boolean;
  } = {
    disabled: false,
    showHighlight: true,
  },
): SerializedStyles {
  const { disabled, showHighlight } = options;
  const { colors } = theme;

  const colorDirection = getColorDirection(shipment, showHighlight ?? false);

  return css`
    color: ${{
      shipping: colors.dockBadgeShipping,
      'shipping-highlight': colors.dockBadgeShipping,
      receiving: colors.dockBadgeReceiving,
      'receiving-highlight': colors.dockBadgeReceiving,
      mixed: colors.dockBadgeMixed,
      neutral: colors.dockBadgeIdle,
      unknown: colors.dockBadgeUnused,
      unused: colors.dockBadgeUnused,
    }[colorDirection]};

    background: ${disabled
      ? colors.doorIdle
      : {
          shipping: colors.doorShippingBackground,
          'shipping-highlight': colors.doorShippingBackgroundHighlight,
          receiving: colors.doorReceivingBackground,
          'receiving-highlight': colors.doorReceivingBackgroundHighlight,
          mixed: colors.doorNeutralBackground,
          neutral: colors.doorNeutralBackground,
          unknown: colors.doorUnusedBackground,
          unused: colors.doorUnusedBackground,
        }[colorDirection]};

    border-color: ${{
      shipping: colors.dockBadgeShipping,
      'shipping-highlight': colors.dockBadgeShipping,
      receiving: colors.dockBadgeReceiving,
      'receiving-highlight': colors.dockBadgeReceiving,
      mixed: colors.dockBadgeMixed,
      neutral: colors.dockBadgeIdle,
      unknown: colors.dockBadgeUnused,
      unused: colors.dockBadgeUnused,
    }[colorDirection]};
  `;
}
