import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type { TextFieldProps } from '@mui/material/TextField';
import TextField from '@mui/material/TextField';

type KargoTextFieldProps = TextFieldProps & {
  showBackground?: boolean;
};

const KargoTextField = styled(
  ({ InputProps, FormHelperTextProps, ...props }: KargoTextFieldProps) => (
    <TextField
      variant='outlined'
      size='small'
      InputProps={{
        ...InputProps,
        classes: {
          input: 'kargo-text-field-input',
          notchedOutline: 'kargo-text-field-notched-outline',
          error: 'kargo-text-field-input--error',
          focused: 'kargo-text-field--focused',
          ...InputProps?.classes,
        },
      }}
      FormHelperTextProps={{
        ...FormHelperTextProps,
        classes: {
          root: 'kargo-text-field-form-helper-root',
          ...FormHelperTextProps?.classes,
        },
      }}
      {...props}
    />
  ),
  {
    shouldForwardProp: (prop) => !['showBackground'].includes(prop),
  },
)`
  border: none;

  & .kargo-text-field-form-helper-root {
    position: absolute;
    top: 40px;
  }

  & .kargo-text-field-notched-outline,
  .kargo-text-field--focused {
    border: none;

    :hover,
    :focus-visible {
      border: none;
    }
  }

  ${(p) =>
    p.showBackground &&
    css`
      & .kargo-text-field-input {
        background-color: ${p.theme.colors.white};
      }
    `}
`;

export { KargoTextField };
