import { AdminLinkButton } from '@layouts/dashboard/admin-link-button';
import { useRouter } from 'next/router';

type Props = {
  toAdmin: boolean;
};

const DashboardAdminLinkButton = ({ toAdmin }: Props) => {
  const router = useRouter();

  const setCurrentDashboardUrlInLocalStorage = () => {
    window.localStorage.setItem(
      'latest_dashboard_page_url',
      JSON.stringify(window.location.href),
    );
  };

  const goToStoredUrl = () => {
    const base = `${window.origin}`;
    const latestDashboardPageUrl = window.localStorage.getItem(
      'latest_dashboard_page_url',
    );

    if (latestDashboardPageUrl) {
      router.push(JSON.parse(latestDashboardPageUrl));
    } else {
      router.push(base);
    }
  };

  return (
    <>
      {toAdmin && (
        <AdminLinkButton
          setCurrentDashboardUrlInLocalStorage={
            setCurrentDashboardUrlInLocalStorage
          }
        />
      )}
    </>
  );
};

export { DashboardAdminLinkButton };
